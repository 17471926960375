<template>
  <div class="StatisticsParkList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
    >
      <template #searchSlot>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-datepicker label="时间" :startTime.sync="searchParams.beginDate" :endTime.sync="searchParams.endDate"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { getListURL, exportListURL } from './api'

export default {
  name: 'StatisticsParkList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityParams,
      searchParams: {
        communityId: '',
        beginDate: '',
        endDate: ''
      },
      headers: [
        {
          prop: 'statisticsDate',
          label: '时间',
          formatter (row, prop, index) {
            if (index === 0) {
              return '合计'
            } else {
              return row.statisticsDate
            }
          }
        },
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'parkTimes',
          label: '车位预订次数（次）'
        },
        {
          prop: 'totalParkTime',
          label: '总停时长（小时）'
        },
        {
          prop: 'totalPaidAmount',
          label: '停车总费用（元）'
        },
        {
          prop: 'ownerIncome',
          label: '业主收入（元）'
        },
        {
          prop: 'propertyIncome',
          label: '物业收入（元）'
        },
        {
          prop: 'platformIncome',
          label: '平台收入（元）'
        },
        {
          prop: 'communityIncome',
          label: '业委会收入（元）'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
